<template>
    <PageHeader :title="title" />

    <componentCalendar />

</template>

<script>
import PageHeader from "@/components/page-header";
import componentCalendar from '@/components/calendar/indexPM'

export default {
  data() {
    return {
      title: this.$t('Calendar') + " PM",
    }
  },
  components: {
    PageHeader,
    componentCalendar
  },
  created(){
    
  },
  methods: {
    
  },
};
</script>

